<template>
  <section class="logistics-edit" v-show="!isLoading">
    <div v-if="baseConfig" class="logistics-info xa-cell__box xa-bg-white">
      <div>{{ baseConfig.title }}</div>
      <div class="xa-txt-primary  xa-txt-14 xa-cell">
        <div class="xa-txt-secondary logistics-info__label">收件人：</div>
        <div>{{ baseConfig.name }}</div>
        <div class="xa-txt-blue" v-if="baseConfig.phone">
          ({{ baseConfig.phone }})
        </div>
      </div>
      <div class="xa-txt-primary  xa-txt-14 xa-cell">
        <div class="xa-txt-secondary logistics-info__label">收件地址：</div>
        <div>{{ baseConfig.address }}</div>
      </div>
    </div>
    <div class="xa-txt-red xa-txt-center xa-cell__box">
      {{ baseConfig && baseConfig.tip }}
    </div>
    <div
      v-for="(item, index) in express"
      :key="index + item.name"
      class="xa-bg-white xa-cell xa-cell__box xa-line__bottom xa-txt-14"
    >
      <div class="xa-flex mail-cell__border" @click.stop="onEdit(index)">
        <div>物流方式：{{ item.name }}</div>
        <div v-show="item.name !== '自送'">快递单号： {{ item.code }}</div>
      </div>
      <div class="xa-txt-red" @click.stop="onDeleItem(index)">
        <span class="xa-txt-17 iconfont icon-ttpodicon"></span>
      </div>
    </div>
    <div class="logistics-add__btn">
      <van-button icon="plus" type="info" @click="onAdd">新增物流信息</van-button>
    </div>
    <van-popup
      v-model="showPopup"
      position="bottom"
      :style="{ height: '50%' }"
      round
    >
      <div class="xa-view xa-com-container">
        <van-cell-group class="xa-flex">
          <van-cell
            :title="(editingIndex > -1 ? '编辑' : '新增') + ' 物流信息'"
          />
          <van-field
            label="物流方式"
            :value="logisticsName"
            left-icon="logistics"
            right-icon="arrow"
            placeholder="请选择物流公司/方式"
            readonly
            @click.native="onSelectLogisticsName"
          />
          <van-field
            v-show="logisticsName !== '自送'"
            label="快递单号"
            v-model="logisticsCode"
            left-icon="orders-o"
            placeholder="请录入快递单号"
            :right-icon="isApp ? 'scan' : ''"
            @click-right-icon="scanQrCode"
          />
        </van-cell-group>
        <div class="xa-cell">
          <van-button
            class="xa-flex"
            :disabled="closeBtnDisabled"
            type="info"
            @click="closePop"
            >确定</van-button
          >
        </div>
      </div>
    </van-popup>
    <EnsureFootBtn :disabled="express.length == 0" @submit="onSubmit" />
  </section>
</template>
<script>
// 填写物流信息
import basePage from '@/mixins/basePage'
import { Button, Popup, Field } from 'vant'
import EnsureFootBtn from '@/components/EnsureFootBtn'
import appController, { isApp } from '@/controllers/app-android'
const LogisticsNames = [
  '自送',
  '顺丰',
  '申通',
  '圆通',
  '韵达',
  '中通',
  '天天',
  '安能',
  '德邦',
  '其他公司'
]
export default {
  name: 'Logistics-Edit',
  mixins: [basePage, EnsureFootBtn],
  config: {
    title: '寄件物流信息',
    fetchDataFn: 'initView',
    canRefresh: false
  },
  components: {
    VanButton: Button,
    VanPopup: Popup,
    VanField: Field,
    EnsureFootBtn
  },
  data() {
    return {
      isApp,
      isLoading: true,
      showPopup: false,
      baseConfig: null,
      actions: null,
      express: [],
      editingIndex: -1,
      logisticsName: '',
      logisticsCode: ''
    }
  },
  computed: {
    closeBtnDisabled() {
      if (this.logisticsName.length === 0) return true // 要选择快递公司
      if (this.logisticsName === '自送') return false // 自送的不需要快递单号
      if (this.logisticsCode.trim().length === 0) return true // 要快递单号
      return false
    }
  },
  methods: {
    async scanQrCode() {
      this.logisticsCode = await appController.toScanQRCode()
    },
    closePop() {
      this.showPopup = false
      const item = {
        name: this.logisticsName,
        code: this.logisticsCode.trim()
      }
      this.editingIndex > -1
        ? this.express.splice(this.editingIndex, 1, item)
        : this.express.unshift(item)
    },
    async onSelectLogisticsName() {
      this.logisticsName =
        LogisticsNames[await this.showActionSheet(LogisticsNames)]
    },
    onAdd() {
      this.logisticsCode = this.logisticsName = ''
      this.showPopup = true
      this.editingIndex = -1
    },
    onEdit(index) {
      this.editingIndex = index
      this.logisticsName = this.express[index].name
      this.logisticsCode = this.express[index].code
      this.showPopup = true
    },
    async onSubmit() {
      if (this.actions) {
        let action = Array.isArray(this.actions)
          ? this.actions[0]
          : this.actions
        const { data } = this.$_extractRequestInfoFormRoute()
        let ajax = action.ajax || action
        ajax.data = ajax.data || {}
        ajax.data = {
          ...ajax.data,
          ...data,
          express: this.express
        }
        await this.$_submitDataWidthUi(ajax)
        this.$router.go(-1)
      }
    },
    async onDeleItem(index) {
      await this.$_submitDataWidthUi(() => {
        this.express.splice(index, 1)
      }, '确定移除改物流信息？')
    },
    async initView() {
      const { url, data } = this.$_extractRequestInfoFormRoute()
      const result = await this.$_request({ url, data })
      const { info, express = [] } = result.body
      this.baseConfig = info
      this.express = express
      this.actions = result.actions
      this.isLoading = false
      return result
    }
  }
}
</script>
<style lang="scss" scoped>
.logistics-edit {
  min-height: 100vh;
  padding-bottom: 60px;
}
.logistics-info {
  &__label {
    flex-shrink: 0;
    min-width: 5em;
    align-self: flex-start;
  }
}
.logistics-add__btn {
  padding: 32px 0;
  display: flex;
  justify-content: center;
}
</style>
