<template>
  <van-submit-bar
    class="es-foot-btn__box"
    v-bind="$props"
    @submit="$emit('submit')"
  />
</template>
<script>
import { SubmitBar } from 'vant'
export default {
  components: {
    VanSubmitBar: SubmitBar,
  },
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: '确定',
    },
    buttonType: {
      type: String,
      default: 'info',
    },
  },
}
</script>
<style lang="scss">
.es-foot-btn__box {
  .van-submit-bar__button {
    width: 100%;
  }
}
</style>
